/* eslint-disable no-console */
import Axios, { AxiosError } from "axios";
import config from "config";
import urls from "config/urls";
import Cookies from "js-cookie";
import Router from "next/router";
import { QueryClient } from "react-query";
import { AuthenticatedUser } from "types/User/AuthenticatedUser";
// eslint-disable-next-line import/no-cycle
import { logoutUser } from "utils";
import { GeoLocationCords } from "modules/candidate/profile/hooks/getNearestLocation";
import { DocumentAssetQuestionsResponse } from "modules/candidate/profile/types/document-asset-types";
import { EvaluationDataResponse } from "modules/candidate/profile/types/english-evaluation-source-types";
import {
    LOCAL_STORAGE_KEYS,
    SESSION_STORAGE_KEYS,
    USER_AUTH_TOKEN,
    USER_DETAIL_KEY,
} from "./constants";

let IS_LOGOUT_FOR_REVALIDATION_INITIATED = false;

export const axiosInstanceWithAuth = Axios.create({
    baseURL: config.baseUrl,
});

export const rawClient = Axios.create({
    baseURL: config.baseUrl,
});

axiosInstanceWithAuth.interceptors.request.use((req) => {
    if (typeof window !== "undefined") {
        req.headers.Authorization = `Token ${Cookies.get(USER_AUTH_TOKEN) || "4379a3026f6d520fef969cae237192a991cf1421"
            }`;
    }
    return req;
});

export const axiosInstanceWithCandidateAuth = Axios.create({
    baseURL: config.baseUrl,
});

axiosInstanceWithCandidateAuth.interceptors.request.use((req) => {
    const candidateAccessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    if (typeof window !== "undefined") {
        req.headers.Authorization = `Token ${candidateAccessToken}`;
    }
    return req;
});

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 20000,
            onError: async (error) => {
                if (
                    ((error as AxiosError).response?.status as number) === 401 &&
                    !IS_LOGOUT_FOR_REVALIDATION_INITIATED
                ) {
                    try {
                        await logoutUser();
                        IS_LOGOUT_FOR_REVALIDATION_INITIATED = true;
                        if (Router.pathname !== "/") {
                            Router.push("/jobs");
                        }
                    } catch (requestError) {
                        console.error("Logout Failed : ", requestError);
                    }
                }
            },
        },
    },
});

export const getJobsAuth = (filter, additionalHeader = {}) => {
    const URL = `${urls.USER_JOBS_HORIZONTAL}${filter}`;
    return axiosInstanceWithAuth.get(URL, { ...additionalHeader });
};

export const getCategoriesAuth = (additionalHeader = {}) => {
    const URL = `${urls.AUTH_CATEGORIES}`;
    return axiosInstanceWithAuth.get(URL, { ...additionalHeader });
};

export const getAreasInCity = (cityId, additionalHeader = {}) => {
    const URL = `${urls.USER_AREAS_IN_CITY.replace("{cityId}", cityId)}`;
    return axiosInstanceWithAuth.get(URL, { ...additionalHeader });
};

export const getOnboardingCategories = () =>
    axiosInstanceWithAuth.get(urls.SUPERCATEGORIES_URL);

export const getOnboardingCities = () => axiosInstanceWithAuth.get(urls.CITY_LIST_URL);

export const getOnboardingEducationLevels = () =>
    axiosInstanceWithAuth.get(urls.EDUCATION_LEVEL_URL);

export const updateUserProfile = (data) => {
    const URL = `${urls.UPDATE_USER_PROFILE_V3}/${data.id}/?source=onboarding_experience`;
    return axiosInstanceWithAuth.put(URL, data);
};

export const updateUserInterests = (data) => {
    const URL = `${urls.UPDATE_USER_INTERESTS}`;
    return axiosInstanceWithAuth.put(URL, data);
};

export const getUserProfileData = (user) => {
    const URL = urls.USER_PROFILE_URL.replace("{user}", user);
    return axiosInstanceWithAuth.get<AuthenticatedUser>(URL);
};

export const getCandidateDetails = () =>
    axiosInstanceWithCandidateAuth.get(urls.CANDIDATE_DETAILS_URL, {
        params: {
            ensure_root_loc: true,
        },
    });

export const getCandidateJobDetails = () =>
    axiosInstanceWithCandidateAuth.get(urls.CANDIDATE_JOB_DETAILS_URL);

export const getCandidateProfile = () => {
    const URL = urls.UPDATE_USER_PROFILE;
    return axiosInstanceWithCandidateAuth.get(URL);
};

export const getCandidateConfiguration = () =>
    axiosInstanceWithCandidateAuth.get(urls.CANDIDATE_CONFIG);

export const getPreferredJobTypesV2Enabled = () => {
    const URL = urls.PREFERRED_JOB_TYPES_V2_ENABLED;
    return axiosInstanceWithCandidateAuth.get(URL);
};

export const getUserProfileDetails = (userId) => {
    const URL = `${urls.PROFILE_USER_URL}${userId}`;
    return axiosInstanceWithCandidateAuth.get(URL);
};

export const getCandidateProgress = () => {
    const URL = urls.CANDIDATE_PROFILE_COMPLETION_PROGRESS_URL;
    return axiosInstanceWithCandidateAuth.get(URL);
};

export const getCandidateOnboardingComplete = () => {
    const onBoardingFlowType = sessionStorage.getItem(
        SESSION_STORAGE_KEYS.ONBOARDING_FLOWTYPE
    );
    const canvasKey = sessionStorage.getItem(SESSION_STORAGE_KEYS.CANVAS_KEY);
    let URL = `${urls.CANDIDATE_ONBOARDING_COMPLETE_URL}${onBoardingFlowType ? `?source=${onBoardingFlowType}` : ""
        }`;
    const pathname = Router.pathname;
    if (canvasKey === "canvas" && pathname.includes("/competitions")) {
        URL = `${urls.CANDIDATE_ONBOARDING_COMPLETE_URL}?source=apnaCanvas&version=v3`;
    }

    return axiosInstanceWithCandidateAuth.get(URL);
};

export const getPrivateJobDetailsV7 = (jobID: string) => {
    const URL = `${urls.PUBLIC_URL_JOB_V7 + jobID}?isWebRequest=true`;
    return axiosInstanceWithCandidateAuth.get(URL);
};

export const getCandidateEligibility = (jobId: string, userId: string) => {
    const URL = `${urls.CANDIDATE_ELIGIBILITY_URL}${userId}/${jobId}`;
    return axiosInstanceWithCandidateAuth.get(URL);
};

export const getCandidateWebApplications = () => {
    const URL = `${urls.REGISTER_JOB_APPLICATION_ON_WEB_URL}`;
    return axiosInstanceWithCandidateAuth.get(URL);
};

export const getNearestGioLocation = (location: GeoLocationCords) => {
    const URL = `${urls.GET_NEAREST_LOCATION}?latitude=${location.latitude}&longitude=${location.longitude}`;
    return axiosInstanceWithCandidateAuth.get(URL);
};
export const getCandidateJobPreference = () => {
    const URL = urls.WORK_TYPE_PREFERENCES;
    return axiosInstanceWithCandidateAuth.get(URL);
};

export const getCandidateFeed = (query, firstCall = false) =>
    Axios.get(`${config.baseUrl}${urls.CANDIDATE_JOB_FEED_URL}`, {
        params: { ...query, firstCall },
        headers: {
            Authorization: `Token ${query.c_id}`,
        },
    });

export const getWebConfigDetails = async (query) => {
    const response = await Axios.get(`${config.baseUrl}${urls.WEB_CONFIG}`, {
        params: { ...query },
        headers: {
            Authorization: `Token ${query.c_id}`,
        },
    });
    return response?.data;
};
export const getJobtitle = async (title) => {
    try {
        const data = await fetch(urls.JOB_TITLE_SUGGESTIONS.replace("{title}", title));
        if (data.ok && data.status === 200) {
            const result = await data.json();
            return result;
        }
        return [];
    } catch (error) {
        return [];
    }
};

export const getUniversityName = async (title = "", limit = 15) => {
    try {
        const URL = `${urls.UNIVERSITY_SUGGESTIONS
            }?keyword=${title.toLowerCase()}&limit=${limit}&is_educational=yes`;

        return await axiosInstanceWithAuth.get(URL).then((res) => {
            if (res.data?.length > 0) {
                return res;
            }
            return { data: [{ name: title, id: -1, verification_status: "pending" }] };
        });
    } catch (error) {
        return [];
    }
};

export const getCompanyName = async (title = "", limit = 15) => {
    try {
        const URL = `${urls.COMAPANY_SUGGESTIONS
            }?keyword=${title.toLowerCase()}&limit=${limit}`;

        return await axiosInstanceWithAuth.get(URL).then((res) => {
            if (res.data?.length > 0) {
                return res;
            }
            return { data: [{ name: title, id: -1, verification_status: "pending" }] };
        });
    } catch (error) {
        return [];
    }
};

export const getExperienceSkillSuggestion = (skillSearchKeyword: string) => {
    const URL = urls.GET_SKILLS_SUGGESTIONS.replace("{searchText}", skillSearchKeyword);
    return axiosInstanceWithCandidateAuth.get(URL);
};

export const getDegreeName = async (args, limit = 15) => {
    const { title = "", educationLevel } = args;
    try {
        const URL = `${urls.DEGREE_SUGGESTIONS}?keyword=${title}&size=${limit}&education_level=${educationLevel}&verification_status=verified`;

        return await axiosInstanceWithAuth.get(URL).then((res) => {
            if (res.data?.length > 0) {
                return res;
            }
            return { data: [{ level: title, id: -1, verification_status: "pending" }] };
        });
    } catch (error) {
        console.log({ error });
        return [];
    }
};

export const createDegree = async (title = "") => {
    try {
        const URL = `${urls.CREATE_DEGREE}`;

        return await axiosInstanceWithAuth.post(URL, { level: title });
    } catch (error) {
        return { data: null };
    }
};

export const sendViEvent = async (eventName, data) => {
    const userDetails = JSON.parse(localStorage.getItem(USER_DETAIL_KEY) || "{}");

    const phoneNumber = (userDetails?.phone_number as string)?.startsWith("+")
        ? userDetails?.phone_number.slice(3)
        : userDetails?.phone_number;
    const isVi = window.location.href.includes("microsite/vi");

    try {
        if (phoneNumber && isVi) {
            const URL = `${urls.VI_ANALYTICS_URL}`;
            return await axiosInstanceWithAuth.post(
                URL,
                {
                    eventName,
                    data,
                    phoneNumber,
                },
                {
                    baseURL: "/",
                }
            );
        }
        return null;
    } catch (error) {
        return { data: null };
    }
};

export const logout = () => {
    const URL = `${config.oauthBaseUrl + urls.LOGOUT}`;
    return axiosInstanceWithCandidateAuth.post(URL, null);
};

export const applyJob = (userId, jobId) => {
    const URL = `${urls.APPLY_JOB_URL}`;
    return axiosInstanceWithCandidateAuth.post(URL, {
        user: userId,
        job: jobId,
        status: "apply",
        source: "WEB_APNACO",
    });
};

export const registerJobApplicationOnWeb = () => {
    const URL = urls.REGISTER_JOB_APPLICATION_ON_WEB_URL;
    return axiosInstanceWithCandidateAuth.post(URL, {});
};

export const getCitiesList = () => {
    const URL = `${urls.GET_JOB_CITIES}`;
    return axiosInstanceWithCandidateAuth.get(URL);
};
export const getCandidateJobDepartments = () => {
    const URL = `${urls.GET_CANDIDATE_DEPARTMENT}`;
    return axiosInstanceWithCandidateAuth.get(URL);
};

export const getSearchCandidateDepartment = (searchKey) => {
    const URL = `${urls.SEARCH_CANDIDATE_DEPARTMENT.replace(
        "{keyword}",
        searchKey.toLowerCase() || ""
    )}`;
    return axiosInstanceWithCandidateAuth.get(URL);
};

export const fetchDocumentsAssetsQuestions = () => {
    const URL = urls.GET_DOCUMENT_ASSET_QUESTIONS;
    return axiosInstanceWithCandidateAuth.get<DocumentAssetQuestionsResponse>(URL);
};

export const getPreviewDocumentDetails = (key) => {
    const URL = urls.GET_UPLOAD_URL.replace("{key}", key);
    return axiosInstanceWithCandidateAuth.get(URL);
};

export const getLanguageList = () => {
    const URL = `${urls.GET_LANGUAGE_LIST}`;
    return axiosInstanceWithCandidateAuth.get(URL);
};

export const getEnglishEvaluationSourceDetails = (queryParam) => {
    const URL = urls.GET_ENGLISH_EVALUATION_SOURCE.replace("{param}", queryParam);
    return axiosInstanceWithCandidateAuth.get<EvaluationDataResponse>(
        `${config.infra_base_url}/${URL}`
    );
};

export const postExternalJobAppliedFeedback = (data) => {
    const URL = urls.POST_EXTERNAL_JOB_APPLIED_FEEDBACK;
    return axiosInstanceWithCandidateAuth.post(URL, data);
};

export const registerSiliconCandidate = (data) => {
    const isProduction = process.env.ENVIRONMENT === "production";
    const eventId = isProduction
        ? "662f8da02616c356e1f4e512"
        : "662f6ba4675a3618c16ad981";
    const URL = urls.SILICON_REGISTER.replace("%EVENT_ID%", eventId);
    return axiosInstanceWithCandidateAuth.post(`${config.infra_base_url}/${URL}`, data);
};

export const registerationStatusSiliconCandidate = (data) => {
    const isProduction = process.env.ENVIRONMENT === "production";
    const eventId = isProduction
        ? "662f8da02616c356e1f4e512"
        : "662f6ba4675a3618c16ad981";
    const URL = urls.SILICON_REGISTERATION_STATUS.replace("%EVENT_ID%", eventId);
    return axiosInstanceWithCandidateAuth.post(`${config.infra_base_url}/${URL}`, data);
};
export const getECCSuccessScreen = (jobID: string) => {
    const URL = `${urls.ECC_SUCCESS_SCREEN + jobID}`;
    return axiosInstanceWithCandidateAuth.get(URL);
};

export const deleteUserProfile = (token: string) => {
    const URL = urls.DELETE_PROFILE.replace("{%TOKEN%}", token);
    return axiosInstanceWithCandidateAuth.get(URL);
};
