import React from "react";

function ExternalLinkIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<path
				fill="#fff"
				d="M3.333 14c-.366 0-.68-.13-.941-.392A1.284 1.284 0 012 12.667V3.333c0-.366.13-.68.392-.941.26-.261.575-.392.941-.392h4a.667.667 0 010 1.333h-3a1 1 0 00-1 1v7.334a1 1 0 001 1h7.334a1 1 0 001-1v-3a.667.667 0 111.333 0v4c0 .366-.13.68-.392.941a1.284 1.284 0 01-.941.392H3.333zm3.6-4A.66.66 0 016 9.067l5.733-5.734H10A.667.667 0 1110 2h3a1 1 0 011 1v3a.667.667 0 01-1.333 0V4.267L6.933 10z"
			/>
		</svg>
	);
}

export default ExternalLinkIcon;
