/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PenIcon from "assets/svgs/PenIcon";
import clsx from "clsx";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { formatSeconds } from "utils/commonUtils";
import { getCandidateOnboardingComplete } from "utils/apiClientPrivate";
import { useCandidateConfiguration } from "modules/candidate/profile/hooks/useCandidateConfiguration";
import { PRE_APPLIED_FILTERS_FREQUENCY_KEY } from "components/RevampFeedDetails/constants";
import { GTAG_EVENTS, GTag } from "utils/Gtag";
import Mixpanel from "utils/Mixpanel";
import { isCanvasMicroSite } from "utils";
import ErrorMessage from "./components/ErrorMessage";
import OtpInput from "./components/OtpInput";
import { KEY_USER_ID, OTP_LENGTH, USER_AUTH } from "./constants";
import { requestOtp, verifyOtpWithBE } from "./utils/loginApi";
import {
	TokenUtils,
	getLastSavedNumberDetails,
	getRemainingVerificationAttempts,
	saveNumberDetails,
} from "./utils/loginUtils";
import useSafePush from "../../hooks/useSafePush";
import {
	trackOnBoardingLoginSuccess,
	trackWebOtpEntered,
	trackWebOtpError,
} from "./utils/analyticsUtils";
import { LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS } from "../../utils/constants";

const OTP_RESEND_TIMEOUT = 30;
const OTP_NOT_RECEIVED = "Didn’t get the OTP?";
const ERROR = "Incorrect OTP";

const OTPScreen = ({ onCloseModal }) => {
	const router = useRouter();
	const { safePush } = useSafePush();

	const [otpError, setOtpError] = useState<string>("");
	const [otpValue, setOtpValue] = useState<string>("");
	const timerRef = useRef<NodeJS.Timeout | number>(0);
	const [timer, setTimer] = useState<number>(OTP_RESEND_TIMEOUT);
	const details = getLastSavedNumberDetails();
	const { refetch: fetchCandidateConfig } = useCandidateConfiguration();
	const mobileNumber = details?.mobileNumber;

	const verifyOtp = async (otp: string) => {
		if (!details) return;

		const res = await verifyOtpWithBE(mobileNumber, otp);

		// if (res.data?.timeout) {
		// 	blockVerification();
		// 	trackMixpanelEvent(LOGIN_SCREEN_MIXPANEL_EVENTS.BLIND_HR_OTP_VERIFY_FAILED, {
		// 		otp: Number(otp),
		// 		phone_number: Number(mobileNumber),
		// 	});
		// 	return;
		// }

		// if (res.data?.is_blacklisted && res.data?.detail) {
		// 	trackMixpanelEvent(LOGIN_SCREEN_MIXPANEL_EVENTS.BLIND_HR_OTP_VERIFY_FAILED, {
		// 		otp: Number(otp),
		// 		phone_number: Number(mobileNumber),
		// 	});
		// 	setError(res.data.detail);
		// 	setBlacklisted(true);
		// 	return;
		// }

		const isCorrect = res?.status === 200;

		if (!isCorrect) {
			saveNumberDetails({
				...details,
				lastVerificationAttemptAt: Date.now(),
				verificationAttemptsMade: details.verificationAttemptsMade + 1, // increase attempts
			});
			const attemptsLeft = getRemainingVerificationAttempts();

			if (attemptsLeft !== 0) {
				setOtpError(
					`Incorrect OTP. You have ${
						attemptsLeft > 0 ? attemptsLeft : 0
					} attempt(s) left`
				);
				trackWebOtpError(
					`Incorrect OTP. You have ${
						attemptsLeft > 0 ? attemptsLeft : 0
					} attempt(s) left`,
					mobileNumber
				);
				return;
			}
			trackWebOtpError(ERROR, mobileNumber);
			setOtpError(ERROR);
		} else {
			// Correct otp entered
			const { tokens, user } = res.data;
			TokenUtils.setToken(tokens.access);
			localStorage.setItem("__user__", JSON.stringify(user));
			localStorage.setItem(KEY_USER_ID, user.id);
			localStorage.removeItem(PRE_APPLIED_FILTERS_FREQUENCY_KEY);

			const response = await getCandidateOnboardingComplete();

			if (localStorage.getItem("user_id")) {
				Mixpanel.identify(user?.id);
			}
			const candidateConfiguration = await fetchCandidateConfig();

			if (
				response.status === 200 &&
				typeof response?.data?.is_profile_complete !== "undefined"
			) {
				const userType = response.data?.is_profile_complete
					? "old enriched user"
					: response.data?.profile_type === "new"
					? "new user"
					: "old non enriched user";
				const isProfileEnriched = response.data?.is_profile_complete;
				localStorage.setItem(
					LOCAL_STORAGE_KEYS.IS_ONBOARDING_COMPLETE,
					isProfileEnriched
				);
				trackOnBoardingLoginSuccess(userType, mobileNumber, isProfileEnriched);
			}

			const match = router.asPath.match(/returnTo=([^&]+)/);
			const aicteReturnUrl = match ? decodeURIComponent(match[1]) : "";

			/**
			 * Non Enriched user
			 */

			if (response.status === 200 && response.data?.is_profile_complete === false) {
				onCloseModal({ updatedPathname: undefined, queryObj: undefined });
				const targetJobID = sessionStorage.getItem(
					"jobApplicationBeforeLoginTriggered"
				);
				const onBoardingFlowType = sessionStorage.getItem(
					SESSION_STORAGE_KEYS.ONBOARDING_FLOWTYPE
				);
				/**
				 * In case before the redirection the user_auth query params where not removed from router.asPath
				 * automatically, we will mutate the asPath to not send user_auth=otp_verification
				 */
				const mutatedPathWithoutAuthParam = router.asPath.replace(
					/(\?|&)user_auth=otp_verification/g,
					""
				);

				// No need for redirection for Canvas microsite
				if (isCanvasMicroSite(router.pathname)) {
					return;
				}

				/**
				 * In case the login has been initiated from a delete account page, there is no need of redirection.
				 */
				if (router.pathname === "/delete-account") {
					return;
				}
				if (router.pathname === "/job/[...job]" && targetJobID) {
					/**
					 * In case the login has been initiated from a job page, we need to redirect the user to the job page
					 */
					window.location.href = `/${
						candidateConfiguration?.data?.targetOnboardingRootRoute
					}?returnTo=${mutatedPathWithoutAuthParam}&&jobId=${targetJobID}${
						onBoardingFlowType ? `&&flowType=${onBoardingFlowType}` : ""
					}`;
				} else {
					if (aicteReturnUrl.length > 0) {
						/**
						 * If user came from aicte website, pass on the returnTo param to blindHR
						 */
						window.location.href = `/${
							candidateConfiguration?.data?.targetOnboardingRootRoute
						}?returnTo=${aicteReturnUrl}${
							onBoardingFlowType ? `&&flowType=${onBoardingFlowType}` : ""
						}`;
						return;
					}

					window.location.href = `/${
						candidateConfiguration?.data?.targetOnboardingRootRoute
					}?returnTo=${
						router.pathname === "/" ? "/jobs" : mutatedPathWithoutAuthParam
					}${onBoardingFlowType ? `&&flowType=${onBoardingFlowType}` : ""}`;
				}
			} else {
				/**
				 * Enriched User
				 */
				let pathname;
				let qObj;

				if (aicteReturnUrl.length > 0) {
					/**
					 *
					 * Enriched user coming from aicte website with returTo param
					 *
					 */
					delete router.query?.user_auth;
					delete router.query?.returnTo;
					qObj = { ...router.query };

					if (aicteReturnUrl === "/jobs") {
						pathname = `/candidate/jobs`;
						qObj = { ...qObj, c_id: tokens.access };
					} else {
						pathname = aicteReturnUrl;
					}
				} else if (router.pathname === "/") {
					/**
					 * Default behaviour for modal onClose on Homepage
					 * If user is on the homepage redirect him to the /candidate/jobs?c_id post auth flow
					 * else simply close the modal.
					 */
					qObj = { c_id: TokenUtils.getToken() };

					pathname = "/candidate/jobs";
				} else if (["/jobs/[[...paths]]"].includes(router.pathname)) {
					/**
					 *
					 * Enriched user logging on jobs page
					 *
					 */

					delete router.query?.user_auth;
					qObj = { ...router.query, c_id: tokens.access };
					pathname = router.asPath.includes("?")
						? router.asPath?.split("?")?.[0]
						: router.asPath;

					pathname = `/candidate${pathname}`;
				}

				onCloseModal({ updatedPathname: pathname, queryObj: qObj });
			}
		}
	};

	const handleInputChange = (event: string) => {
		setOtpError("");

		const enteredOTP = event;
		setOtpValue(enteredOTP);
		if (enteredOTP?.length === OTP_LENGTH) {
			trackWebOtpEntered(mobileNumber);
			verifyOtp(enteredOTP);
			GTag(GTAG_EVENTS.VerifyOtp);
		}
	};

	const clearTimer = () => {
		clearInterval(timerRef.current);
		timerRef.current = 0;
	};
	const startTimer = (startFrom: number = OTP_RESEND_TIMEOUT) => {
		clearTimer();
		setTimer(startFrom);
		timerRef.current = setInterval(() => {
			setTimer((time: number) => {
				if (time <= 0) {
					clearInterval(timerRef.current);
					timerRef.current = 0;
					return 0;
				}
				return time - 1;
			});
		}, 1000);
	};
	const handleResendClick: React.MouseEventHandler<Element> = async () => {
		if (timer === 0) {
			startTimer();
			setOtpValue("");
			setOtpError("");
			const res = await requestOtp(mobileNumber);
			if (res.status === 200) {
				saveNumberDetails({
					mobileNumber,
					otpSentAt: Date.now(),
					verificationAttemptsMade: 0,
					otpSendTries: (details?.otpSendTries || 0) + 1,
				});
			}
		}
	};

	const handleEditClick = () => {
		// Redirect to mobile number input screen

		safePush(
			`${router.asPath}`.replace(
				`user_auth=${USER_AUTH.OTP_SCREEN}`,
				`user_auth=${USER_AUTH.NUMBER_SCREEN}`
			),
			undefined,
			{
				shallow: true,
			}
		);
	};

	useEffect(() => {
		startTimer();
	}, []);

	return (
		<div className="p-6">
			<h3 className="text-onSurface-primary mb-[12px] self-start text-xl font-bold">
				Enter OTP
			</h3>
			<div className="flex text-[14px]">
				We have sent an OTP on : +91-{mobileNumber}
				<div className="ml-[8px] cursor-pointer" onClick={handleEditClick}>
					<PenIcon fill="#1F8268" />
				</div>
			</div>
			<div className="mt-[16px] flex justify-center">
				<OtpInput
					handleInputChange={handleInputChange}
					otpValue={otpValue}
					isOtpError={!!otpError}
				/>
			</div>
			<ErrorMessage message={otpError} error={!!otpError} />
			<div
				className={clsx("mt-[16px] flex flex-row", {
					"ml-auto mr-[1.6vw]": !!otpError === false,
					"gap-[27px]": !!otpError === true,
					"mt-[16px]": timer !== 0,
					"mt-[39px]": timer === 0,
				})}
			>
				<div
					className={clsx("flex-end ml-auto flex text-[14px] text-[#8C8594]", {
						hidden: timer === 0,
					})}
				>
					{formatSeconds(timer)}
				</div>
			</div>
			<div className="mt-[24px] flex text-[14px]">
				<div>{OTP_NOT_RECEIVED}</div>
				<div
					className={clsx("font-[600]", {
						"cursor-pointer text-[#1F8268]": timer === 0,
						"text-[#8C8594]": timer !== 0,
					})}
					onClick={handleResendClick}
				>
					&nbsp;&nbsp;Resend
				</div>
			</div>
		</div>
	);
};

export default OTPScreen;
